@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
  font-family: "Rubik", sans-serif;
}

body {
  background-color: #f0f0f0;
}


.rtl-form {
  direction: rtl;
  text-align: right;
}

.container {
  padding: 50px;
  margin-top: 150px;
  background-color: white;
  border-radius: 4px;
}

.btn-special{
  color: #ffffff;
  background-color: #DE1F26;
  border-color: #DE1F26;
}

.btn-special:hover, .btn-outline-special:hover,
.btn-special:active, .btn-outline-special:active,
.btn-special:focus, .btn-outline-special:focus {
  color: #ffffff;
  background-color: #a3151b !important;
  border-color: #a3151b !important; 
}

.btn-outline-special {
  color: #DE1F26;
  border-color: #DE1F26;
}

.custom-list {
  list-style: none;
  padding-right: 0;
}

.custom-list li {
  position: relative;
  padding-right: 70px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  line-height: 1.4;
  width: 400px;
}

.custom-list li::before {
  font-family: "bootstrap-icons";
  font-size: 55px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.quiet::before {
  content: "\f60d";
}

.time::before {
  content: "\f597";
}

.email::before {
  content: "\f84c";
}

.copy-link-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 700px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01);
}

#copy-link-input {
  flex: 1;
  padding: 10px 12px;
  border: none;
  font-size: 14px;
  outline: none;
  background: #f8f9fa;
}

#copy-link-button {
  padding: 10px 16px;
  background-color: #3e3e3e;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.2s;
}

#copy-link-button:hover {
  background-color: #333333;
}

#copy-link-button:active {
  background-color: #333333;
}


